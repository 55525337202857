<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="400px">
    <v-card>
      <v-card-title>
        <span class="headline">Sil</span>
      </v-card-title>

      <v-card-text>
        <v-container>
          Silmek istediğinize emin misiniz?
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close">
          KAPAT
        </v-btn>
        <v-btn color="red darken-1" text @click="$emit('done')">
          SİL
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: ['visible'],
  data() {
    return {
      loading: true
    };
  },
  methods: {
    close() {
      this.$emit('close');
    }
  }
};
</script>
