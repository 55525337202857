<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-dialog slot="toolbar" v-model="visible" persistent max-width="600px">
    <v-card>
      <form-wrapper :validator="$v.form">
        <v-card-title>
          <span class="headline">Kurs Bilgilerini Düzenle</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <form-group cols="12" name="name">
                <v-text-field
                  slot-scope="{ attrs }"
                  v-bind="attrs"
                  label="Kurs Adı"
                  v-model="form.name"
                  @blur="$v.form.name.$touch()"
                ></v-text-field>
              </form-group>
              <form-group cols="12" md="6" name="startDate">
                <v-menu
                  ref="startDate"
                  v-model="startDate"
                  :close-on-content-click="false"
                  :return-value.sync="form.startDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.startDate"
                      label="Başlama Tarihi"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.startDate"
                    locale="tr"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="startDate = false"
                      >İptal</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.startDate.save(form.startDate)"
                      >Bitti</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </form-group>
              <form-group cols="12" md="6" name="endDate">
                <v-menu
                  ref="endDate"
                  v-model="endDate"
                  :close-on-content-click="false"
                  :return-value.sync="form.endDate"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="form.endDate"
                      label="Bitiş Tarihi"
                      readonly
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form.endDate"
                    locale="tr"
                    no-title
                    scrollable
                  >
                    <v-spacer></v-spacer>
                    <v-btn text color="primary" @click="endDate = false"
                      >İptal</v-btn
                    >
                    <v-btn
                      text
                      color="primary"
                      @click="$refs.endDate.save(form.endDate)"
                      >Bitti</v-btn
                    >
                  </v-date-picker>
                </v-menu>
              </form-group>
              <form-group cols="12" name="description">
                <v-textarea
                  label="Açıklama"
                  v-model="form.description"
                ></v-textarea>
              </form-group>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="close">
            Kapat
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            :disabled="$v.$anyError"
            @click="submit"
          >
            Kaydet
          </v-btn>
        </v-card-actions>
      </form-wrapper>
    </v-card>
  </v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';
import _ from 'lodash';

export default {
  mixins: [validationMixin],
  validations: {
    form: {
      name: { required }
    }
  },

  data() {
    return {
      visible: false,
      loading: true,
      form: {},
      course: {},
      startDate: false,
      endDate: false
    };
  },

  methods: {
    async submit() {
      try {
        await this.axios.patch(
          this.namespace + '/courses/' + this.course.id,
          this.form
        );

        for (let key in this.form) {
          this.course[key] = this.form[key];
        }

        this.close();
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde güncellendi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Güncelleme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    open(course) {
      this.course = course;
      this.visible = true;
    },
    close() {
      this.visible = false;
      this.$v.$reset();
    }
  },

  watch: {
    visible: {
      handler(nv) {
        if (nv) {
          this.form = _.cloneDeep(this.course);
          this.form.startDate = this.course.startDate.split('T')[0];
          this.form.endDate = this.course.endDate.split('T')[0];
        }
      }
    }
  }
};
</script>
