<template>
  <div class="d-flex align-items-center justify-content-between mb-2">
    <span class="font-weight-bold mr-2">{{ field }}:</span>
    <span class="text-muted">{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: 'DetailListItem',
  props: ['field', 'value']
};
</script>
