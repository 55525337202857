<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div>
    <card :title="title">
      <v-menu slot="toolbar" offset-y>
        <template v-slot:activator="{ on }">
          <v-btn color="primary" outlined dark v-on="on">
            İşlemler
          </v-btn>
        </template>
        <v-list>
          <v-list-item @click="courseAddModal = true">
            Kurs Ekle
          </v-list-item>
        </v-list>
      </v-menu>
      <v-data-table :headers="headers" :items="courses" :loading="loading">
        <template v-slot:item.name="{ item }">
          <router-link
            :to="{
              name: instructor ? 'course' : 'panel-course',
              params: { courseId: item.id }
            }"
          >
            {{ item.name }}
          </router-link>
        </template>
        <template v-slot:item.start="{ item }">
          {{ moment(item.startDate).format('D MMMM YYYY') }}
        </template>
        <template v-slot:item.end="{ item }">
          {{ moment(item.endDate).format('D MMMM YYYY') }}
        </template>

        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="$refs.courseEditModal.open(item)"
            >mdi-pencil</v-icon
          >
          <v-icon
            small
            @click="
              deletePromt = true;
              deleteId = item.id;
            "
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </card>
    <DeletePromt
      :visible="deletePromt"
      @close="deletePromt = false"
      @done="deleteCourse()"
    ></DeletePromt>
    <CourseUpdate ref="courseEditModal" />
    <CourseAdd
      :instructor="instructor"
      :school-id="schoolId"
      :visible="courseAddModal"
      @close="courseAddModal = false"
      @done="getCourseList()"
    ></CourseAdd>
  </div>
</template>

<script>
import moment from 'moment';
import 'moment/locale/tr';
import CourseAdd from './CourseAdd';
import DeletePromt from '@/view/content/components/DeletePromt';
import CourseUpdate from '@/view/pages/admin/course/CourseUpdate';

export default {
  props: ['instructor', 'schoolId'],
  components: { CourseAdd, DeletePromt, CourseUpdate },
  data() {
    return {
      title: 'Kurs Listesi',
      headers: [
        { text: 'Kurs Adı', value: 'name' },
        { text: 'Başlangıç', value: 'start' },
        { text: 'Bitiş', value: 'end' },
        { text: 'İşlemler', value: 'actions' }
      ],
      courses: [],
      courseAddModal: false,
      deletePromt: false,
      deleteId: null,
      loading: true
    };
  },
  mounted() {
    this.getCourseList();
  },
  methods: {
    async getCourseList() {
      try {
        let data;
        if (this.instructor) {
          const result = await this.axios.get(
            'admin/instructors/' + this.instructor.id + '/courses'
          );
          data = result.data;
        } else {
          const result = await this.axios.get(
            'panel/schools/' + this.schoolId + '/courses'
          );
          data = result.data;
        }

        this.courses = data;
        this.loading = false;
      } catch (e) {
        console.log(e);
      }
    },
    async deleteCourse() {
      try {
        await this.axios.delete(this.namespace + '/courses/' + this.deleteId);

        this.getCourseList();
        this.deletePromt = false;
        this.deleteId = null;
        this.$store.dispatch('setToast', {
          text: 'Başarılı bir şekilde silindi!',
          color: 'success'
        });
      } catch (e) {
        this.$store.dispatch('setToast', {
          text: 'Silme esnasında hata meydana geldi!',
          color: 'danger'
        });
        console.log(e);
      }
    },
    moment
  }
};
</script>
