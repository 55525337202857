<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <v-app>
    <b-col>
      <b-alert
        show
        variant="light"
        class="alert alert-custom alert-white alert-shadow fade show gutter-b"
      >
        <div class="alert-icon">
          <span class="svg-icon svg-icon-lg">
            <inline-svg src="/media/svg/icons/Tools/Compass.svg" />
          </span>
        </div>
        <div class="alert-text">
          Kurum bilgilerini güncelleyebilir ve yeni eğitmen ekleyebilirsiniz.
        </div>
      </b-alert>
      <b-row v-if="school.iller">
        <b-col md="4">
          <card title="Kurum Bilgileri">
            <detail-list-item field="Kurum Adı" :value="school.name" />
            <detail-list-item field="Şehir" :value="school.iller.il_adi" />
            <detail-list-item field="İlçe" :value="school.ilceler.ilce_adi" />
            <detail-list-item field="Semt" :value="school.semtler.semt_adi" />
            <detail-list-item
              field="Mahalle"
              :value="school.mahalleler.mahalle_adi"
            />
          </card>
        </b-col>
        <b-col md="8">
          <CourseList :schoolId="schoolId" />
        </b-col>
      </b-row>
    </b-col>
  </v-app>
</template>

<script>
import { SET_BREADCRUMB } from '@/store/breadcrumbs.module';
import DetailListItem from '@/view/content/components/DetailListItem.vue';
import CourseList from '@/view/content/components/Course/CourseList';

export default {
  props: ['schoolId'],
  components: { DetailListItem, CourseList },
  data() {
    return {
      school: {}
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Kurumlar' },
      { title: 'Kurum Detay' }
    ]);
    this.getSchool();
  },
  methods: {
    async getSchool() {
      try {
        const { data } = await this.axios.get('panel/schools/' + this.schoolId);

        this.school = data;
      } catch (e) {
        console.log(e);
      }
    }
  }
};
</script>
